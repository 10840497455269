<template>
  <div
    :data-id="user.uuid"
    class="member-item tw-flex tw-items-center"
  >
    <ctk-avatar
      :user="user"
      class="tw-mr-5 tw-flex-fixed"
      data-test="avatar"
    />
    <div class="tw-flex-1 tw-flex tw-flex-col tw-leading-tight tw-truncate">
      <div
        v-if="!isNoAccessUser"
        v-text="name"
        class="tw-text-base tw-font-medium tw-truncate"
        data-test="name"
      />
      <hover-on-croppable
        :value="user.email"
        class="tw-text-sm tw-text-secondary-text tw-truncate"
        data-test="email"
      />
      <div
        v-if="isNoAccessUser"
        class="tw-flex tw-justify-start tw-items-center tw-gap-1"
        data_test="no_access"
      >
        <span
          v-text="$t('account.members.labels.no_access')"
          class="tw-text-sm tw-text-secondary-text tw-italic"
          data-test="no_access_label"
        />
        <div>
          <img
            id="no_access"
            src="@/assets/img/icons/info_inverted.svg"
            alt=""
            width="14"
            height="14"
            data-test="no_access_icon"
          >
          <b-tooltip
            target="no_access"
          >
            {{ $t('account.members.paragraphs.no_access') }}
          </b-tooltip>
        </div>
      </div>
    </div>
    <div class="member-item__status-container tw-flex tw-justify-end tw-ml-2">
      <InviteActions
        v-if="isNoAccessUser"
        :member-type="memberType"
        :actions="memberActions"
        @member-send="send"
        @member-remove="remove"
      />

      <div
        v-else-if="user.is_owner"
        class="tw-rounded-full tw-text-secondary-lighten tw-bg-gray-500 tw-px-2 tw-py-1 tw-text-xs tw-my-auto"
        v-text="$t('account.members.labels.admin')"
        data-test="status"
      />

      <ui-button
        v-else-if="isCompanyOwner && !user.is_owner && hasRemove"
        v-b-tooltip.hover
        :title="$t('account.members.buttons.remove')"
        variant="link"
        size="sm"
        data-test="delete-button"
        @click="remove"
      >
        <template #left-icon>
          <ui-ctk-icon
            name="trash"
            data-test="icon"
          />
        </template>
      </ui-button>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, toRefs, computed } from '@vue/composition-api'

  import { showToaster } from '@/services/Toaster'

  import useStore from '@/composables/useStore'
  import useI18n from '@/composables/useI18n'

  import CtkAvatar from '@/components/CtkAvatar/index.vue'
  import HoverOnCroppable from '@/components/UI/HoverOnCroppable/index.vue'
  import UiCtkIcon from '@/components/UI/Icon/CtkIcon/index.vue'
  import InviteActions from '@/views/Common/Account/Members/components/InviteItem/components/InviteActions/index.vue'

  /**
   * @module component - MemberItem
   * @param {Object} user - The user object
   * @param {String} user.uuid - The user UUID
   * @param {String} user.first_name - The user first name
   * @param {String} user.last_name - The user last name
   */
  export default defineComponent({
    components: {
      CtkAvatar,
      HoverOnCroppable,
      InviteActions,
      UiCtkIcon
    },
    props: {
      user: {
        type: Object,
        required: true
      },
      hasRemove: {
        type: Boolean,
        default: true
      }
    },
    emits: ['remove'],
    setup (props, { emit }) {
      const { user } = toRefs(props)
      const store = useStore()
      const i18n = useI18n()

      const { uuid, email } = user.value

      const name = computed(() => {
        const { first_name: firstName, last_name: lastName } = user.value
        return `${firstName} ${lastName}`
      })
      const isCompanyOwner = computed(() => store.value.getters['auth/isCompanyOwner'])
      const isNoAccessUser = computed(() => user && user.value && user.value.status && user.value.status === 'no_access')

      const memberActions = ref(['send', 'remove'])
      const memberType = ref('member')

      function remove () {
        emit('remove')
      }

      function send () {
        store.value.dispatch('account/requestInvite', {
          email,
          existingUser: uuid
        })
          .then(() => {
            close()

            showToaster(null, i18n.value.t('account.members.paragraphs.invite_sent'), {
              type: 'success',
              position: 'bottom-right'
            })
          })
          // @ts-ignore
          .catch(err => {
            if (!err.response) return

            const { data } = err.response

            if (data && data.error && data.error.violations) {
              showToaster(null, data.error.title, {
                type: 'error',
                position: 'bottom-right'
              })
            }
          })
      }

      return {
        isCompanyOwner,
        isNoAccessUser,
        memberActions,
        memberType,
        name,
        remove,
        send
      }
    }
  })
</script>

<style lang="scss" scoped>

  .member-item__status-container {
    min-width: 100px;
  }

</style>
